import  React, { Fragment }  from "react"
import Dash from "./Dash"
// import Listing from './Listing'
const Dashboard=()=>
{
    return(
        <Fragment>
            <Dash/>
            {/* <Listing/> */}
        </Fragment>
    )
}

export default Dashboard