import React, { Fragment } from 'react'
import Footer from '../../Home/Footer'
import Header from '../../Home/Header'
import Cat1 from './Cat1'

const Cat=()=>
{
    return(
        <Fragment>
            <Header/>
            <Cat1/>
            <Footer/>
        </Fragment>
    )
}

export default Cat